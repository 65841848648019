import {AdminApiDal} from '../adminApiDal';
import {inject, injectable} from '../../../../modules/common/di';
import {CreateChallengeApiResponse, CreateChallengeModel} from './createChallengeApiResponse';
import {Uri} from '../../../../modules/common/uriHelper';
import {ChallengeStatus, ChallengeType, GetChallengesApiResponse, ParticipationStatus} from './getChallengesApiResponse';
import {UpdateChallengeApiResponse, UpdateChallengeModel} from './updateChallengeApiResponse';
import {DeleteChallengeApiResponse} from './deleteChallengeApiResponse';
import {UpdateChallengeStatusApiResponse} from './updateChallengeStatusApiResponse';
import {CreateOrUpdateActivityApiResponse, CreateOrUpdateActivityModel} from './createOrUpdateActivityApiResponse';
import {ActivityStatus, GetUserActivitiesApiResponse} from './getUserActivitiesApiResponse';
import {GetChallengeWinnersApiResponse} from './getChallengesWinnersApiResponse';
import {GetChallengeParticipantsApiResponse} from './getChallengesParticipantsApiResponse';
import {UpdateChallengeParticipationStatusApiResponse} from './updateChallengeParticipationStatusApiResponse';
import {GetDemandResponseChallengeEnergyUsageApiResponse} from './getDemandResponseChallengeEnergyUsageApiResponse';

/**
 * Challenges
 * API to manage challenges.
 * See {@link https://iotadmins.docs.apiary.io/#reference/challenges}
 */
@injectable('ChallengesApi')
export class ChallengesApi {

  @inject('AdminApiDal') protected readonly dal: AdminApiDal;

  // region Manage Challenges

  /**
   * Create a challenge from scratch or from a template
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/manage-challenges/create-a-challenge}
   *
   * @param {number} organizationId Organization ID to create this challenge for.
   * @param {CreateChallengeModel} challengeModel
   * @param [params] Request parameters
   * @param {boolean} [params.check] true - Check if an active challenge already exists without creating one.
   *     false - Do not check if an active challenge already exists, just create it, default.
   * @param {number} [params.parentId] Challenge or template ID to copy settings from.
   * @returns {Promise<CreateChallengeApiResponse>}
   */
  createChallenge(organizationId: number,
                  challengeModel: CreateChallengeModel,
                  params?: { check?: boolean, parentId?: number }): Promise<CreateChallengeApiResponse> {
    return this.dal.post(`organizations/${Uri.Enc(organizationId)}/challenges`, challengeModel, {params: params});
  }

  /**
   * View a list of challenges selected by their parameters. Only admins can see the templates in the search results.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/manage-challenges/get-challenges}
   *
   * @param {number} organizationId Organization ID.
   * @param [params] Request params
   * @param {ChallengeStatus} [params.status] Optional challenges status filter
   * @param {number} [params.challengeId] Retrieve only this challenge
   * @param {ChallengeType} [params.challengeType] Filter response by challenge types. Multiple values are supported.
   * @param {string} [params.searchBy] Search by name. Use * for a wildcard.
   * @param {number} [params.parentId] Template ID to filter by
   * @returns {Promise<GetChallengesApiResponse>}
   */
  getChallenges(organizationId: number,
                params?: {
                  status?: ChallengeStatus,
                  challengeId?: number,
                  challengeType?: ChallengeType,
                  searchBy?: string,
                  parentId?: number
                }): Promise<GetChallengesApiResponse> {
    return this.dal.get(`organizations/${Uri.Enc(organizationId)}/challenges`, {params: params});
  }

  /**
   * Updates specified challenge in the specified organization.
   * An administrator can modify all template fields except template and challengeType.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/modify-challenges/update-a-challenge}
   *
   * @param {number} organizationId Organization ID to update a challenge within
   * @param {number} challengeId Challenge ID to update
   * @param {UpdateChallengeModel} updateChallengeModel
   * @returns {Promise<UpdateChallengeApiResponse>}
   */
  updateChallenge(organizationId: number,
                  challengeId: number,
                  updateChallengeModel: UpdateChallengeModel): Promise<UpdateChallengeApiResponse> {
    return this.dal.put(
      `organizations/${Uri.Enc(organizationId)}/challenges/${Uri.Enc(challengeId)}`,
      updateChallengeModel);
  }

  /**
   * Deletes specified challenge from the specified organization.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/modify-challenges/delete-a-challenge}
   *
   * @param {number} organizationId Organization ID to update a challenge from
   * @param {number} challengeId Challenge ID to delete
   * @returns {Promise<DeleteChallengeApiResponse>}
   */
  deleteChallenge(organizationId: number, challengeId: number): Promise<DeleteChallengeApiResponse> {
    return this.dal.delete(
      `organizations/${Uri.Enc(organizationId)}/challenges/${Uri.Enc(challengeId)}`);
  }


  /**
   * Updates specified challenge's status with new value.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/modify-challenges/update-challenge-status}
   *
   * @param {number} organizationId Organization ID to update
   * @param {number} challengeId Challenge ID to update
   * @param {ChallengeStatus} status New status:
   *     0 - Inactive challenge
   *     1 - Active challenge
   * @returns {Promise<UpdateChallengeStatusApiResponse>}
   */
  updateChallengeStatus(organizationId: number, challengeId: number, status: ChallengeStatus): Promise<UpdateChallengeStatusApiResponse> {
    return this.dal.put(
      `organizations/${Uri.Enc(organizationId)}/challenges/${Uri.Enc(challengeId)}/status/${Uri.Enc(status)}`
    );
  }

  /**
   * Creates or updates activity or a list of activities for the specified challenge.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/manage-challenge-activities/create-or-update-an-activities}
   *
   * @param {number} organizationId Organization ID.
   * @param {number} challengeId Id of the challenge to create/update activities for.
   * @param {CreateOrUpdateActivityModel} activityModel Activity data to submit.
   * @returns {Promise<CreateOrUpdateActivityApiResponse>}
   */
  createOrUpdateActivity(organizationId: number, challengeId: number, activityModel: CreateOrUpdateActivityModel)
    : Promise<CreateOrUpdateActivityApiResponse> {
    return this.dal.put(
      `organizations/${Uri.Enc(organizationId)}/challenges/${Uri.Enc(challengeId)}/activities`);
  }

  /**
   * Gets user activities for the specified challenge and status.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/manage-challenge-activities/get-user-activities}
   *
   * @param {number} organizationId Organization ID.
   * @param {number} challengeId Challenge ID to retrieve activities for
   * @param [params] Request parameters.
   * @param {ActivityStatus} [params.status] Status filter
   *     0 - Inactive activities
   *     1 - Active activities
   * @param {number} [params.userId] User ID for use by administrators
   * @returns {Promise<GetUserActivitiesApiResponse>}
   */
  getUserActivities(organizationId: number,
                    challengeId: number,
                    params?: {
                      status?: ActivityStatus,
                      userId?: number
                    }): Promise<GetUserActivitiesApiResponse> {
    return this.dal.get(
      `organizations/${Uri.Enc(organizationId)}/challenges/${Uri.Enc(challengeId)}/activities`,
      {params: params});
  }

  /**
   * Gets challenge winners according to the specified filter conditions.
   *
   * Only members of the given organization can retrieve the winners.
   *
   * Winners may contain the place they ranked, user or group information, their result (for example, the number of users recruited or the % energy saved),
   * current month's usage, and usage from this month last year.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/get-winners/get-winners}
   *
   * @param {number} organizationId Organization to get challenge winners for.
   * @param {number} challengeId Challenge ID to obtain winners for
   * @param [params] Request parameters.
   * @param {string} [params.startDate] Optional start date of the challenge
   * @param {string} [params.endDate] Optional end date of the challenge
   * @param {number} [params.winnersLimit] Optional limit to the maximum number of winners per period returned
   * @returns {Promise<GetChallengeWinnersApiResponse>}
   */
  getChallengeWinners(organizationId: number,
                      challengeId: number,
                      params?: {
                        startDate?: string,
                        endDate?: string,
                        winnersLimit?: number
                      }): Promise<GetChallengeWinnersApiResponse> {
    return this.dal.get(
      `organizations/${Uri.Enc(organizationId)}/challenges/${Uri.Enc(challengeId)}/winners`,
      {params: params});
  }

  /**
   * Gets challenge's participants according to the filter criterias.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/challenge-participants/get-participants}
   *
   * @param {number} organizationId Organization to get challenge participants for.
   * @param {number} challengeId Challenge to get participants for.
   * @param [params] Request parameters.
   * @param {ParticipationStatus} [params.status] Participation status filter.
   * @param {number} [params.userId] Filter the response by user ID.
   * @returns {Promise<GetChallengeParticipantsApiResponse>}
   */
  getChallengeParticipants(organizationId: number,
                           challengeId: number,
                           params?: {
                             status?: ParticipationStatus,
                             userId?: number
                           }): Promise<GetChallengeParticipantsApiResponse> {
    return this.dal.get(
      `organizations/${Uri.Enc(organizationId)}/challenges/${Uri.Enc(challengeId)}/participants`,
      {params: params}
    );
  }

  /**
   * Updates challenge participant's status.
   *
   * Only approved members of the given organization and the challenge participants can update their participation status to Opt-In or
   * Opt-Out.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/challenge-participants/update-status}
   *
   * @param {number} organizationId Organization ID.
   * @param {number} challengeId Challenge ID to update participation status in.
   * @param params Request parameters
   * @param {ParticipationStatus} params.status New participation status to set.
   * @param {number} [params.userId] When called by the administrator only.
   * @returns {Promise<UpdateChallengeParticipationStatusApiResponse>}
   */
  updateChallengeParticipationStatus(organizationId: number,
                                     challengeId: number,
                                     params: {
                                       status: ParticipationStatus,
                                       userId?: number
                                     }): Promise<UpdateChallengeParticipationStatusApiResponse> {
    return this.dal.put(
      `organizations/${Uri.Enc(organizationId)}/challenges/${Uri.Enc(challengeId)}/participants`,
      null,
      {params: params});
  }

  /**
   * Return demand response challenge participants energy usage and baseline for the challenge period. The first record contains total information for the
   * whole challenge period. Rest records contain 5 minute interval data.
   *
   * Only approved members of the given organization and the challenge participants can call this API.
   *
   * @see {@link https://iotadmins.docs.apiary.io/#reference/challenges/challenge-energy-usage/get-energy-usage}
   *
   * @param {number} organizationId Organization ID.
   * @param {number} challengeId Challenge ID to get energy usage for.
   * @param [params] Request parameters.
   * @param {number} [params.userId] To get specific user details.
   * @returns {Promise<GetDemandResponseChallengeEnergyUsageApiResponse>}
   */
  getDemandResponseChallengeEnergyUsage(organizationId: number,
                                        challengeId: number,
                                        params?: {
                                          userId?: number
                                        }): Promise<GetDemandResponseChallengeEnergyUsageApiResponse> {
    return this.dal.get(
      `organizations/${Uri.Enc(organizationId)}/challenges/${Uri.Enc(challengeId)}/energyUsage`,
      {params: params});
  }

  // endregion

}
