import {Logger} from '../logger/logger';
import {inject, injectable} from '../common/di';
import {Tuner} from '../tuner/tuner';

@injectable('WcStorage')
export class WcStorage {

  @inject('Logger') private readonly logger: Logger;
  @inject('Tuner') private readonly tuner: Tuner;

  public get<T>(path: string): T {
    if (!path) {
      this.logger.error(`WcStorage.get("${path}"): empty path`);
      throw new Error('Unable to get value with empty path from localStorage');
    }
    // if not in local storage, the string "undefined" is returned sometimes
    let text: string = localStorage.getItem(this.tuner.config.localStoragePrefix + path);
    if (text === null || typeof text === 'undefined' || text === 'undefined') {
      // this.logger.debug(`WcStorage.get("${path}") - path not found, returned undefined`);
      return undefined;
    } else if (text === 'null') {
      return null;
    } else {
      //logger.debug(`WcStorage.read("${path}")`);
      // Try to parse a json
      try {
        return <T>JSON.parse(text);
      } catch (e) {
        return <T><any>text;
      }
    }
  }

  public set(path: string, data: string | number | boolean | object): void {
    if (typeof data === 'undefined') {
      this.logger.error(`WcStorage.set("${path}"): empty data`);
      throw new Error('Unable to save undefined value in localStorage');
    }
    if (!path) {
      this.logger.error(`WcStorage.set("${path}"): empty path`);
      throw new Error('Unable to save value with empty path in localStorage');
    }

    let dataStr: string;
    if (typeof dataStr === 'string' || typeof (dataStr) === 'boolean' || typeof (dataStr) === 'number') {
      dataStr = data.toString();
    } else {
      dataStr = JSON.stringify(data);
    }

    //logger.debug(`WcStorage.set("${path}", "${dataStr}")`);
    localStorage.setItem(this.tuner.config.localStoragePrefix + path, dataStr);
  }

  public remove(path: string): void {
    if (!path) {
      this.logger.error(`WcStorage.remove("${path}"): empty path`);
      return;
    }
    //logger.debug(`WcStorage.remove("${path}")`);
    localStorage.removeItem(this.tuner.config.localStoragePrefix + path);
  }

  /**
   * Clear all Local Storage entries
   * WARNING! Use this feature carefully, because it will remove all data stored in localStorage, e.g. login info
   */
  public clear() {
    localStorage.clear();
  }
}
