import {AdminApiDal} from '../adminApiDal';
import {inject, injectable} from '../../../../modules/common/di';
import {GetOrganizationUsersApiResponse} from './getOrganizationUsersApiResponse';
import {GetOrganizationLocationsApiResponse} from './getOrganizationLocationsApiResponse';
import {CreateLocationInOrganizationApiResponse, CreateLocationInOrganizationModel} from './createLocationInOrganizationApiResponse';
import {EditLocationInOrganizationApiResponse, EditLocationInOrganizationModel} from './editLocationInOrganizationApiResponse';
import {AddOrUpdateOrRemoveLocationsApiResponse, AddOrUpdateOrRemoveLocationsModel} from './addOrUpdateOrRemoveLocationsApiResponse';
import {PostInvitationApiResponse, PostInvitationModel} from './postInvitationApiResponse';
import {CreateGroupApiResponse, CreateGroupModel} from './createGroupApiResponse';
import {GetGroupsApiResponse, GroupType} from './getGroupsApiResponse';
import {UpdateGroupApiResponse, UpdateGroupModel} from './updateGroupApiResponse';
import {GetNarrativesApiResponse, NarrativeType} from '../../app/locations/getNarrativesApiResponse';
import {NarrativePriority, NarrativeStatus} from '../../app/locations/createOrUpdateNarrativeApiResponse';
import {ApiResponseBase} from '../../../models/apiResponseBase';

/**
 * Users and Locations API.
 * API to manage users, organizations, locations, etc.
 * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations}
 */
@injectable('UsersAndLocationsApi')
export class UsersAndLocationsApi {

  @inject('AdminApiDal') protected readonly dal: AdminApiDal;

  // #region Locations in an Organization

  /**
   * Gets locations.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/locations-in-an-organization/get-locations}
   *
   * @param [params] Request parameters.
   * @param {number} [params.organizationId] Organization ID.
   * @param {number} [params.groupId] Group ID.
   * @param {number} [params.locationId] Location ID to retrieve this location.
   * @param {boolean} [params.tree] Retrieve all sub-locations in the selected location tree.
   * @param {string} [params.searchBy] Search by location name and address, user's email or phone number. Use * for a wildcard.
   * @param {number|number[]} [params.priorityCategory] Filter by Location priority. Multiple supported.
   *   {@see GetOrganizationLocationsLocationApiResponse.priorityCategory}
   * @param {number} [params.locationType] Search field - Location type.
   * @param {string|string[]} [params.searchTag] Search by location tag.
   * @param {string|string[]} [params.searchDeviceTag] Search by device tag.
   * @param {number|number[]} [params.deviceType] Search by devices of these types. Multiple values supported.
   * @param {number} [params.servicePlanId] Search by service plan ID.
   * @param {number} [params.stateId] Search field - Location address state ID.
   * @param {number} [params.countryId] Search field - Location address country ID.
   * @param {boolean} [params.getTags] Return location tags.
   * @param {number} [params.limit] Limit the response size.
   * @returns {Promise<GetOrganizationLocationsApiResponse>}
   */
  getOrganizationLocations(params?: {
    organizationId?: number,
    groupId?: number,
    locationId?: number,
    tree?: boolean,
    searchBy?: string,
    priorityCategory?: number | number[],
    locationType?: number,
    searchTag?: string | string[],
    searchDeviceTag?: string | string[],
    deviceType?: number | number[],
    servicePlanId?: number,
    stateId?: number,
    countryId?: number,
    getTags?: boolean,
    limit?: number
  }): Promise<GetOrganizationLocationsApiResponse> {
    return this.dal.get(`locations`, {params: params});
  }

  /**
   * Creates location in organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/locations-in-an-organization/create-location}
   *
   * @param {number} organizationId Organization ID.
   * @param {CreateLocationInOrganizationModel} locationModel Model containing location properties to set.
   * @param [params] Request parameters.
   * @param {number} [params.groupId] Group ID.
   * @param {number} [params.parentId] Parent location ID.
   * @param {number} [params.userId] User ID with admin access to this location.
   * @returns {Promise<CreateLocationInOrganizationApiResponse>}
   */
  createLocationInOrganization(
    organizationId: number,
    locationModel: CreateLocationInOrganizationModel,
    params?: {
      groupId?: number,
      parentId?: number,
      userId?: number
    }): Promise<CreateLocationInOrganizationApiResponse> {
    return this.dal.post(`organizations/${encodeURIComponent(organizationId.toString())}/locations`, locationModel, {params: params});
  }

  /**
   * Edit location in organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/locations-in-an-organization/edit-location}
   *
   * @param {number} organizationId Id of the organization to edit location for.
   * @param {EditLocationInOrganizationModel} model Model containing location properties to set.
   * @param params Request parameters.
   * @param {number} params.locationId Id of the location to edit.
   * @returns {Promise<EditLocationInOrganizationApiResponse>}
   */
  editLocationInOrganization(organizationId: number,
                             model: EditLocationInOrganizationModel,
                             params: { locationId: number }
  ): Promise<EditLocationInOrganizationApiResponse> {
    return this.dal.put(`organizations/${encodeURIComponent(organizationId.toString())}/locations`, model, {params: params});
  }

  /**
   * Deletes specified location in organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/locations-in-an-organization/delete-location}
   *
   * @param {number} organizationId Id of the organization to delete location for.
   * @param params Request parameters.
   * @param {number} params.locationId Id of the location to delete.
   * @returns {Promise<ApiResponseBase>}
   */
  deleteLocationInOrganization(organizationId: number, params: { locationId: number }): Promise<ApiResponseBase> {
    return this.dal.delete(`organizations/${encodeURIComponent(organizationId.toString())}/locations`, {params: params});
  }

  /**
   * Batch API to manage locations in an organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/manage-locations/add-or-update-or-remove-locations}
   *
   * Locations will be added or moved to the organization automatically, if they are not there.
   * To remove locations from a group set the groupId field to 0.
   * To remove locations from the organization set the delete field to true.
   *
   * This API does not guaranty that the request will be performed for all users. The error code may not be returned in this case.
   *
   * @param {number} organizationId Organization ID to search within.
   * @param {AddOrUpdateOrRemoveLocationsModel} model An object that describes what to do with the location
   * @returns {Promise<AddOrUpdateOrRemoveLocationsApiResponse>}
   */
  addOrUpdateOrRemoveLocations(organizationId: number, model: AddOrUpdateOrRemoveLocationsModel): Promise<AddOrUpdateOrRemoveLocationsApiResponse> {
    return this.dal.put(`organizations/${encodeURIComponent(organizationId.toString())}/locationStatus`, model);
  }

  // #endregion

  // #region Organization Narratives

  /**
   * Organization narratives for the specified organization and the child organizations.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/narratives/get-organization-narratives}
   *
   * The search results are organized by "pages". Each page contains a set of elements sorted by the 'narrativeTime' in descending order.
   * The pages follow one another in reverse chronological order.
   *
   * @param {number} organizationId Organization ID to get narratives from.
   * @param params Requested parameters.
   * @param {number} params.rowCount Mandatory, maximum number of elements per page.
   * @param {number} [params.groupId] Filter by location's group ID.
   * @param {string|string[]} [params.searchTag] Filter by location's tag. Multiple values.
   * @param {number|number[]} [params.locationId] Filter by location IDs. Multiple values.
   * @param {number} [params.narrativeId] Filter by ID.
   * @param {NarrativePriority} [params.priority] Filter by priority higher or equal than that.
   * @param {NarrativePriority} [params.toPriority] Filter by priority less or equal than that.
   * @param {NarrativeStatus} [params.status] Filter by status, deleted are not returned by default.
   * @param {NarrativeType | Array<NarrativeType>} Filter by narrative type, multiple values allowed.
   * @param {string} [params.searchBy] Filter by title or description. Use * for a wildcard.
   * @param {string} [params.startDate] Narrative date range start.
   * @param {string} [params.endDate] Narrative date range end.
   * @param {string} [params.pageMarker] Marker to the next page.
   *
   * @returns {Promise<GetNarrativesApiResponse>}
   */
  getOrganizationNarratives(organizationId: number, params: {
    rowCount: number,
    groupId?: number,
    searchTag?: string | string[],
    locationId?: number | number[],
    narrativeId?: number,
    priority?: NarrativePriority,
    toPriority?: NarrativePriority,
    status?: NarrativeStatus,
    narrativeType?: NarrativeType | Array<NarrativeType>,
    searchBy?: string,
    startDate?: string,
    endDate?: string,
    pageMarker?: string
  }): Promise<GetNarrativesApiResponse> {
    return this.dal.get(`organizations/${encodeURIComponent(organizationId.toString())}/narratives`, {params: params});
  }

  // #endregion

  // #region Users

  /**
   * Retrieve a list of users and their information based on the search criteria.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/get-users/get-users}
   *
   * System administrators can access all users, while Organization administrators can access users from within the organization they manage.
   *
   * @param [params] Request parameters.
   * @param {number} [params.organizationId] Organization ID to search within.
   * @param {number} [params.locationId] Search for a user at a specific location ID.
   * @param {string} [params.searchBy] Searches for matching user login name, ID, email address, phone number, first name, last name. Use * for a wildcard.
   * @param {string} [params.searchAddress] Searches by location address fields: street, city, zip code.
   * @param {number} [params.servicePlanId] Filter users, who have specific service plan.
   * @param {string} [params.searchTag] Search by user tag
   * @param {number} [params.limit] The maximum number of user records to retrieve in this request.
   * @param {boolean} [params.getTags] Return user tags
   * @returns {Promise<GetOrganizationUsersApiResponse>}
   */
  getOrganizationUsers(params?: {
    organizationId?: number,
    locationId?: number,
    searchBy?: string,
    searchAddress?: string,
    servicePlanId?: number,
    searchTag?: string,
    limit?: number,
    getTags?: boolean
  }): Promise<GetOrganizationUsersApiResponse> {
    return this.dal.get(`users`, {params: params});
  }

  /**
   * Invite users to the organization by email addresses or user ID's.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/invite-users/post-invitation}
   *
   * @param {number} organizationId Id of the organization to invite users to.
   * @param {PostInvitationModel} invitees Invitees.
   * @param [params] Request parameters.
   * @param {number} [params.reinvite] Reinvite previously invited users.
   * @returns {Promise<PostInvitationApiResponse>}
   */
  postInvitation(organizationId: number, invitees: PostInvitationModel, params?: { reinvite?: boolean }): Promise<PostInvitationApiResponse> {
    return this.dal.post(`organizations/${encodeURIComponent(organizationId.toString())}/invitees`, invitees, {params: params});
  }

  // #endregion

  // #region Groups of Locations

  /**
   * Creates location group in organization. Groups can only be created by an administrator.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/groups-of-locations/create-a-group}
   *
   * @param {number} organizationId Id of the organization to create group in.
   * @param {CreateGroupModel} group Group properties to create group with.
   * @returns {Promise<CreateGroupApiResponse>}
   */
  createGroup(organizationId: number, group: CreateGroupModel): Promise<CreateGroupApiResponse> {
    return this.dal.post(`organizations/${encodeURIComponent(organizationId.toString())}/groups`, group);
  }

  /**
   * Gets location groups in the specified organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/groups-of-locations/get-groups}
   *
   * @param {number} organizationId
   * @param [params] Request parameters.
   * @param {number} [params.groupId] Search field - Group ID.
   * @param {boolean} [params.subOrg] Search groups within sub organizations.
   * @param {string} [params.searchBy] Search by group name.
   * @param {GroupType} [params.type] Search field - Group type.
   * @returns {Promise<GetGroupsApiResponse>}
   */
  getGroups(organizationId: number, params?: {
    groupId?: number,
    subOrg?: boolean,
    searchBy?: string,
    type?: GroupType,
    locationTotals?: boolean
  }): Promise<GetGroupsApiResponse> {
    return this.dal.get(`organizations/${encodeURIComponent(organizationId.toString())}/groups`, {params: params});
  }

  /**
   * Update specified group in organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/manage-groups/update-a-group}
   *
   * @param {number} organizationId Organization Id to update group within.
   * @param {number} groupId Id of the group to update.
   * @param {UpdateGroupModel} group Model containing group properties to set.
   * @returns {Promise<UpdateGroupApiResponse>}
   */
  updateGroup(organizationId: number, groupId: number, group: UpdateGroupModel): Promise<UpdateGroupApiResponse> {
    return this.dal.put(
      `organizations/${encodeURIComponent(organizationId.toString())}/groups/${encodeURIComponent(groupId.toString())}`,
      group);
  }

  /**
   * Delete specified group in organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/manage-groups/delete-a-group}
   *
   * @param {number} organizationId Organization Id to delete group from.
   * @param {number} groupId Id of the group to delete.
   * @returns {Promise<ApiResponseBase>}
   */
  deleteGroup(organizationId: number, groupId: number): Promise<ApiResponseBase> {
    return this.dal.delete(
      `organizations/${encodeURIComponent(organizationId.toString())}/groups/${encodeURIComponent(groupId.toString())}`);
  }

  /**
   * This API keeps track of the total number of times people have attempted to join a group.
   * It is used during user registration attempts, and is even called if the user has been rejected.
   * See {@link https://iotadmins.docs.apiary.io/#reference/users-and-locations/counting-people/count-the-number-of-people-who-tried-to-join-a-group}
   *
   * @param {number} organizationId Organization Id
   * @param {number} groupId Id of the group.
   * @returns {Promise<ApiResponseBase>}
   */
  countPeopleWhoTriedToJoinGroup(organizationId: number, groupId: number): Promise<ApiResponseBase> {
    return this.dal.put(
      `organizations/${encodeURIComponent(organizationId.toString())}/groups/${encodeURIComponent(groupId.toString())}/selectionCount`);
  }

  // #endregion

}
