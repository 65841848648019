import {AdminApiDal} from '../adminApiDal';
import {inject, injectable} from '../../../../modules/common/di';
import {GetPopularTagsApiResponse, TagType} from './popularTagsApiResponse';
import {ApplyTagsApiResponse, ApplyTagsModel} from './applyTagsApiResponse';
import {ApiResponseBase} from '../../../models/apiResponseBase';

/**
 * Tags API to manage tags.
 * See {@link https://iotadmins.docs.apiary.io/#reference/tags}
 */
@injectable('TagsApi')
export class TagsApi {

  @inject('AdminApiDal') protected readonly dal: AdminApiDal;

  /**
   * Allows to get popular tags.
   * See {@link https://iotadmins.docs.apiary.io/#reference/tags/tags/popular-tags}
   *
   * Get a list of popular tags and their number of occurrences in this Organization.
   *
   * @param {number} organizationId Organization ID.
   * @param params Request parameters.
   * @param {TagType} params.type Tag type filter.
   * @param {number} [params.limit] The maximum number of elements to be returned.
   * @returns {Promise<GetPopularTagsApiResponse>}
   */
  getPopularTags(organizationId: number, params: {
    type: TagType,
    limit?: number
  }): Promise<GetPopularTagsApiResponse> {
    return this.dal.get(`organizations/${encodeURIComponent(organizationId.toString())}/tags`, {params: params});
  }

  /**
   * Allows to apply tags.
   * See {@link https://iotadmins.docs.apiary.io/#reference/tags/tags/apply-tags}
   *
   * @param {number} organizationId Organization ID.
   * @param {ApplyTagsModel} tags Tags to apply.
   * @returns {Promise<ApplyTagsApiResponse>}
   */
  applyTags(organizationId: number, tags: ApplyTagsModel): Promise<ApplyTagsApiResponse> {
    return this.dal.put(`organizations/${encodeURIComponent(organizationId.toString())}/tags`, tags);
  }

  /**
   * Allows to delete tags.
   * See {@link https://iotadmins.docs.apiary.io/#reference/tags/tags/delete-tags}
   *
   * @param {number} organizationId Organization ID.
   * @param params Request parameters.
   * @param {TagType} params.type Tags type.
   * @param {string} params.id The ID of user, location or device.
   * @param {string} params.tag Tag value.
   * @param {number} [params.appId] App ID, if need to delete the app tag.
   * @returns {Promise<ApiResponseBase>}
   */
  deleteTags(organizationId: number, params: {
    type: TagType,
    id: string | number,
    tag: string,
    appId?: number
  }): Promise<ApiResponseBase> {
    return this.dal.delete(`organizations/${encodeURIComponent(organizationId.toString())}/tags`, {params: params});
  }

}
