import {inject, injectable} from '../../modules/common/di';
import {BaseService} from './baseService';
import {AuthService} from './authService';
import {ReportsAdminApi} from '../api/admin/reports/reportsApi';
import {GetAvailableReportsApiResponse, ReportDeliveryType, ReportOutputType} from '../api/admin/reports/getAvailableReportsApiResponse';
import {GetReportExecutionDataApiResponse} from '../api/admin/reports/getReportExecutionDataApiResponse';
import {GetReportGroupsApiResponse} from '../api/admin/reports/getReportGroupsApiResponse';
import {ApiResponseBase} from '../models/apiResponseBase';

@injectable('ReportsService')
export class ReportsService extends BaseService {

  @inject('AuthService') protected readonly authService: AuthService;
  @inject('ReportsAdminApi') protected readonly reportsAdminApi: ReportsAdminApi;

  constructor() {
    super();
  }

  /**
   * Get report groups.
   * @param {number} organizationId Mandatory organization ID.
   * @returns {Promise<GetReportGroupsApiResponse>}
   */
  getReportGroups(organizationId: number): Promise<GetReportGroupsApiResponse> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.reportsAdminApi.getReportGroups(organizationId));
  }

  /**
   * Set organization status for report group.
   *
   * @param {number} organizationId Mandatory organization ID to status for.
   * @param {number} reportGroupId Reports group ID.
   * @param {ReportsGroupOrganizationStatus} status New status: 1 = Active, 2 = Inactive.
   * @returns {Promise<ApiResponseBase>}
   */
  setReportGroupStatus(organizationId: number, reportGroupId: number, status: number): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }
    if (reportGroupId < 1 || isNaN(reportGroupId)) {
      return this.reject(`Report group ID is incorrect [${reportGroupId}].`);
    }
    if (status < 1 || status > 2 || isNaN(status)) {
      return this.reject(`Status is incorrect [${status}].`);
    }

    let parameters = {
      reportGroupId: reportGroupId,
      status: status
    };

    return this.authService.ensureAuthenticated()
      .then(() => this.reportsAdminApi.setReportGroupStatus(organizationId, parameters));
  }

  /**
   * This API provide information about available reports.
   *
   * @param [params] Requested parameters.
   * @param {number} [params.reportId] Optional filter by report ID.
   * @param {number} [params.reportGroupId] Optional filter by report group ID.
   * @param {number} [params.organizationId] Optional filter by organization ID.
   * @param {boolean} [params.analytic] Optional filter by 'analytic' report flag.
   * @returns {Promise<GetAvailableReportsApiResponse>}
   */
  getAvailableReports(params?: {
    reportId: number,
    reportGroupId: number,
    organizationId: number,
    analytic: boolean
  }): Promise<GetAvailableReportsApiResponse> {
    return this.authService.ensureAuthenticated()
      .then(() => this.reportsAdminApi.getAvailableReports(params));
  }

  /**
   * Generate specific report.
   * The result can provided in CSV or HTML format. Long generated reports can be delivered by email.
   * All report parameters should be provided in the query string.
   *
   * @param params Requested parameters.
   * @param {number} params.reportId Report ID.
   * @param {ReportOutputType} params.outputType Output format: 0 - CVS, 1 - HTML.
   * @param {ReportDeliveryType} params.deliveryType Way to return report data:
   *  0 - in the response body
   *  1 - as attachment with the Content-Disposition HTTP header
   *  2 - by email
   * @param {number} [params.organizationId] Generate report for specific organization.
   * @returns {Promise<ArrayBuffer|string|Object>}
   */
  generateReport(params: {
    reportId: number,
    outputType: ReportOutputType,
    deliveryType: ReportDeliveryType,
    organizationId?: number,
    [reportParamName: string]: string | number | boolean
  }): Promise<ArrayBuffer | string | Object> {
    return this.authService.ensureAuthenticated()
      .then(() => this.reportsAdminApi.generateReport(params));
  }

  /**
   * Report execution data.
   * The API returns the saved data of the report executions. Reports are executed according to the schedules defined for the report groups to which they
   * belong.
   *
   * @param params Requested parameters.
   * @param {number} params.reportId Report ID.
   * @param {number} params.reportGroupId Report group ID.
   * @param {number} [params.organizationId] Organization ID, reguired for organizational report group.
   * @param {string|number} [params.startDate]
   * @param {string|number} [params.endDate]
   * @returns {Promise<GetReportExecutionDataApiResponse>}
   */
  getReportExecutionData(params: {
    reportId: number,
    reportGroupId: number,
    startDate: number | string,
    endDate: number | string,
    organizationId?: number
  }): Promise<GetReportExecutionDataApiResponse> {
    return this.authService.ensureAuthenticated()
      .then(() => this.reportsAdminApi.getReportExecutionData(params));
  }
}
