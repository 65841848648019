import {Environment} from './environment';
import {LiteEvent} from '../common/liteEvent';
import {injectable} from '../common/di';

const ENV_FIELD_NAME: string = 'environment'; // name of the field on the `window` object
const DEFAULT_ENV: Environment = 'dev'; // Default environment

/**
 * Module to rule the environment
 */
@injectable('Envir')
export class Envir {

  private _environment: Environment;

  public readonly onChangeEvent = new LiteEvent<Environment>();

  public get environment(): Environment {
    return this._environment;
  }

  public set environment(theEnvironment: Environment) {
    if (theEnvironment !== this._environment) {
      this._environment = theEnvironment;
      this.onChangeEvent.trigger(this._environment);
    }
  }

  constructor() {
    if (window[ENV_FIELD_NAME]) {
      this._environment = <Environment>window[ENV_FIELD_NAME];
      delete window[ENV_FIELD_NAME];
    }
    if (this._environment === null) {
      this._environment = DEFAULT_ENV;
    }

    let me = this;

    // Crating "environment" property on window object
    Object.defineProperty(window, ENV_FIELD_NAME, {
      get: function (): Environment {
        return me._environment;
      },
      set: function (newValue: Environment) {
        if (newValue !== me._environment) {
          me._environment = newValue;
          me.onChangeEvent.trigger(this._environment);
        }
      },
      configurable: false
    });

  }
}
