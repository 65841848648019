import {AdminApiDal} from '../adminApiDal';
import {AppApiDal} from '../../app/appApiDal';
import {inject, injectable} from '../../../../modules/common/di';
import {GetRolesApiResponse} from './getRolesApiResponse';
import {ApiResponseBase} from '../../../models/apiResponseBase';
import {SearchUsersApiResponse} from './searchUsersApiResponse';

/**
 * Administrators API.
 * The IoT Software Suite is a role-based access control system.
 * Each role based on permissions, see in {@link https://iotapps.docs.apiary.io/#reference/user-accounts}
 * See {@link https://iotadmins.docs.apiary.io/#reference/administrators}
 */
@injectable('AdministratorsApi')
export class AdministratorsApi {

  @inject('AdminApiDal') protected readonly dal: AdminApiDal;
  @inject('AppApiDal') protected readonly appDal: AppApiDal;

  /**
   * Returns administrative Roles which can be granted to another user by an administrator.
   * See {@link https://iotadmins.docs.apiary.io/#reference/administrators/roles/get-roles}
   *
   * @returns {Promise<GetRolesApiResponse>}
   */
  getRoles(): Promise<GetRolesApiResponse> {
    return this.dal.get('roles');
  }

  /**
   * Grants specified specified role to user.
   * A role can be granted or revoked only by an administrator with a corresponding privilege.
   * See {@link https://iotadmins.docs.apiary.io/#reference/administrators/grant-administrative-roles/grant-administrative-roles}
   *
   * @param {number} userId User ID to grant this role to.
   * @param {number} roleId The Role ID to grant. This is specified by the "Get Roles" API.
   * @returns {Promise<ApiResponseBase>}
   */
  grantAdministrativeRole(userId: number, roleId: number): Promise<ApiResponseBase> {
    return this.dal.put(`users/${encodeURIComponent(userId.toString())}/roles/${encodeURIComponent(roleId.toString())}`);
  }

  /**
   * Revoke administrative roles. This means, that user will have only basic User role.
   * See {@link https://iotadmins.docs.apiary.io/#reference/administrators/revoke-administrative-roles/revoke-administrative-roles}
   *
   * @param {number} userId User ID for which to revoke administrative privileges.
   * @returns {Promise<ApiResponseBase>}
   */
  revokeAdministrativeRoles(userId: number): Promise<ApiResponseBase> {
    return this.dal.delete(`users/${encodeURIComponent(userId.toString())}/roles`);
  }

  /**
   * Hidden API to search users by email or phone across cloud.
   *
   * @param [params] Request parameters.
   * @param {string|string[]} [params.email] Email to search user by. Multiple allowed.
   * @param {number|number[]} [params.phone] Phone number to search user by. Multiple allowed.
   * @returns {Promise<SearchUsersApiResponse>}
   */
  searchUsers(params?: {
    email?: string | string[],
    phone?: number | number[]
  }): Promise<SearchUsersApiResponse> {
    return this.appDal.get('users', {params: params});
  }

}
