import {ReportApiDal} from './reportApiDal';
import {inject, injectable} from '../../../../modules/common/di';
import {GetAvailableReportsApiResponse, ReportDeliveryType, ReportOutputType} from './getAvailableReportsApiResponse';
import {CloudConfigService} from '../../../services/cloudConfigService';
import {ResponseType} from 'axios';
import {GetReportExecutionDataApiResponse} from './getReportExecutionDataApiResponse';
import {GetReportGroupsApiResponse, ReportsGroupOrganizationStatus} from './getReportGroupsApiResponse';
import {ApiResponseBase} from '../../../models/apiResponseBase';

/**
 * Organization Reports API.
 * See {@link https://iotadmins.docs.apiary.io/#reference/reports}
 *
 * The IoT Software Suite provides online reports for different purposes.
 */
@injectable('ReportsAdminApi')
export class ReportsAdminApi {

  @inject('ReportApiDal') protected readonly dal: ReportApiDal;
  @inject('CloudConfigService') protected readonly cloudConfigService: CloudConfigService;

  /**
   * Get report groups.
   * See {@link https://iotadmins.docs.apiary.io/#reference/reports/report-groups/get-groups}
   *
   * @param {number} organizationId Mandatory organization ID.
   * @returns {Promise<GetReportGroupsApiResponse>}
   */
  getReportGroups(organizationId: number): Promise<GetReportGroupsApiResponse> {
    return this.dal.get(`groups/${encodeURIComponent(organizationId.toString())}`);
  }

  /**
   * Set organization status for report group.
   * See {@link https://iotadmins.docs.apiary.io/#reference/reports/report-groups/put-group-organization-status}
   *
   * @param {number} organizationId Mandatory organization ID to status for.
   * @param params Requested parameters.
   * @param {number} params.reportGroupId Reports group ID.
   * @param {ReportsGroupOrganizationStatus} params.status New status: 1 = Active, 2 = Inactive.
   * @returns {Promise<ApiResponseBase>}
   */
  setReportGroupStatus(organizationId: number, params: {
    reportGroupId: number,
    status: ReportsGroupOrganizationStatus
  }): Promise<ApiResponseBase> {
    return this.dal.put(`groups/${encodeURIComponent(organizationId.toString())}`, void 0, {
      params: params
    });
  }

  /**
   * This API provide information about available reports.
   * See {@link https://iotadmins.docs.apiary.io/#reference/reports/available-reports/get-reports}
   *
   * @param [params] Requested parameters.
   * @param {number} [params.reportId] Optional filter by report ID.
   * @param {number} [params.reportGroupId] Optional filter by report group ID.
   * @param {number} [params.organizationId] Optional filter by organization ID.
   * @param {boolean} [params.analytic] Optional filter by 'analytic' report flag.
   * @returns {Promise<GetAvailableReportsApiResponse>}
   */
  getAvailableReports(params?: {
    reportId?: number,
    reportGroupId?: number,
    organizationId?: number,
    analytic?: boolean
  }): Promise<GetAvailableReportsApiResponse> {
    return this.dal.get('reports', {
      params: params
    });
  }

  /**
   * Generate specific report.
   * See {@link https://iotadmins.docs.apiary.io/#reference/reports/generate-report/generate-report}
   *
   * The result can provided in CSV or HTML format. Long generated reports can be delivered by email.
   * All report parameters should be provided in the query string.
   *
   * @param params Requested parameters.
   * @param {number} params.reportId Report ID.
   * @param {ReportOutputType} params.outputType Output format: 0 - CVS, 1 - HTML.
   * @param {ReportDeliveryType} params.deliveryType Way to return report data:
   *  0 - in the response body
   *  1 - as attachment with the Content-Disposition HTTP header
   *  2 - by email
   * @param {number} [params.organizationId] Generate report for specific organization.
   * @returns {Promise<ArrayBuffer|string|Object>}
   */
  generateReport(params: {
    reportId: number,
    outputType: ReportOutputType,
    deliveryType: ReportDeliveryType,
    organizationId?: number,
    [reportParamName: string]: string | number | boolean
  }): Promise<ArrayBuffer | string | Object> {
    let contentType: ResponseType = 'json';
    if (params.deliveryType === ReportDeliveryType.ResponseBody) {
      contentType = 'text';
    } else if (params.deliveryType === ReportDeliveryType.Attachment) {
      contentType = 'arraybuffer';
    } else if (params.deliveryType === ReportDeliveryType.Email) {
      // Server return text in that case for some reason
      contentType = 'text';
    }

    return this.dal.get('generate', {
      params: params,
      responseType: contentType
    });
  }

  /**
   * Get report execution data.
   * See {@link https://iotadmins.docs.apiary.io/#reference/reports/report-execution-data/get-report-execution-data}
   *
   * @param params Requested parameters.
   * @param {number} params.reportId Report ID.
   * @param {number} params.reportGroupId Report group ID.
   * @param {number} [params.organizationId] Organization ID, reguired for organizational report group.
   * @param {string|number} [params.startDate]
   * @param {string|number} [params.endDate]
   * @returns {Promise<GetReportExecutionDataApiResponse>}
   */
  getReportExecutionData(params: {
    reportId: number,
    reportGroupId: number,
    organizationId?: number,
    startDate?: string | number,
    endDate?: string | number
  }): Promise<GetReportExecutionDataApiResponse> {
    return this.dal.get('data', {
      params: params
    });
  }

}
