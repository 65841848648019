import {ApiResponseBase} from '../../../models/apiResponseBase';

export enum ReportOutputType {
  CSV = 0,
  HTML = 1
}

export enum ReportDeliveryType {
  ResponseBody = 0,
  Attachment = 1,
  Email = 2
}

export enum ReportDataType {
  String = 1,
  Integer = 2,
  DateTime = 3,
  Decimal = 4,
  Boolean = 5
}

export interface GetAvailableReportsApiResponse extends ApiResponseBase {
  reports?: Array<{
    id: number;
    name: string;

    /* Analytic report flag */
    analytic: boolean;

    desc?: string;

    /* Available parameters of the report */
    params?: Array<{
      id: string;
      name: string;
      type: ReportDataType;
      required: boolean;
      desc?: string;
    }>;

    /* Output fields of the report */
    fields?: Array<{
      id: string;
      index: number;
      name: string;
      type: ReportDataType;
      desc?: string;
    }>;

    /* Report groups current report attached to */
    reportGroups?: Array<{
      id: number;
      startDate: string;
      startDateMs: number;
      description?: string;
      organizationStatus?: number;
      organizationId?: number;

      /* Parameter values set for the scheduled report */
      parameters?: {
        [id: string]: string | number | boolean;
      };

      /* CRON schedule, if set for scheduled report */
      schedule?: string;
    }>;
  }>;
}
