import {inject, injectable} from '../../../modules/common/di';
import {OrganizationsApi} from './organizations/organizationsApi';
import {AdministratorsApi} from './administrators/administratorsApi';
import {UsersAndLocationsApi} from './usersAndLocations/usersAndLocationsApi';
import {TagsApi} from './tags/tagsApi';
import {DevicesAdminApi} from './devices/devicesApi';
import {ReportsAdminApi} from './reports/reportsApi';

@injectable('AdminApi')
export class AdminApi {

  @inject('AdministratorsApi') public readonly administrators: AdministratorsApi;
  @inject('OrganizationsApi') public readonly organizations: OrganizationsApi;
  @inject('UsersAndLocationsApi') public readonly usersAndLocations: UsersAndLocationsApi;
  @inject('DevicesAdminApi') public readonly devices: DevicesAdminApi;
  @inject('TagsApi') public readonly tags: TagsApi;
  @inject('ReportsAdminApi') public readonly reports: ReportsAdminApi;

}
