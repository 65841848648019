import {inject, injectable} from '../../modules/common/di';
import {OrganizationsApi} from '../api/admin/organizations/organizationsApi';
import {UsersAndLocationsApi} from '../api/admin/usersAndLocations/usersAndLocationsApi';
import {BaseService} from './baseService';
import {GetOrganizationUsersApiResponse} from '../api/admin/usersAndLocations/getOrganizationUsersApiResponse';
import {GetGroupsApiResponse, GroupType} from '../api/admin/usersAndLocations/getGroupsApiResponse';
import {UpdateUserApiResponse} from '../api/app/userAccounts/updateUserApiResponse';
import {GetPopularTagsApiResponse, TagType} from '../api/admin/tags/popularTagsApiResponse';
import {TagsApi} from '../api/admin/tags/tagsApi';
import {AuthService} from './authService';
import {ListObjectsAndPropertiesApiResponse} from '../api/admin/organizations/listObjectsAndPropertiesApiResponse';
import {GetOrganizationAdministratorsApiResponse} from '../api/admin/organizations/getOrganizationAdministratorsApiResponse';
import {GetOrganizationsApiResponse, GetOrganizationsApiResponseOrganization} from '../api/admin/organizations/getOrganizationsApiResponse';
import {AdministratorsApi} from '../api/admin/administrators/administratorsApi';
import {GetRolesApiResponse} from '../api/admin/administrators/getRolesApiResponse';
import {CreateOrganizationApiResponse, CreateOrganizationModel} from '../api/admin/organizations/createOrganizationApiResponse';
import {UpdateOrganizationApiResponse, UpdateOrganizationModel} from '../api/admin/organizations/updateOrganizationApiResponse';
import {GetOrganizationLocationsApiResponse} from '../api/admin/usersAndLocations/getOrganizationLocationsApiResponse';
import {LocationType} from '../api/app/locations/editLocationApiResponse';
import {CreateGroupApiResponse, CreateGroupModel} from '../api/admin/usersAndLocations/createGroupApiResponse';
import {UpdateGroupApiResponse, UpdateGroupModel} from '../api/admin/usersAndLocations/updateGroupApiResponse';
import {OrganizationPropertyModel} from '../api/admin/organizations/setOrganizationPropertiesApiResponse';
import {ApiResponseBase} from '../models/apiResponseBase';
import {GetOrganizationTotalsApiResponse} from '../api/admin/organizations/getOrganizationTotalsApiResponse';
import {GetNarrativesApiResponse, NarrativeType} from '../api/app/locations/getNarrativesApiResponse';
import {NarrativePriority, NarrativeStatus} from '../api/app/locations/createOrUpdateNarrativeApiResponse';
import {WsSubscription} from '../../modules/wsHub/wsSubscription';
import {WsSubscriptionType} from '../../modules/wsHub/wsSubscriptionType';
import {WsSubscriptionOperation} from '../../modules/wsHub/wsSubscriptionOperation';
import {WsHub} from '../../modules/wsHub/wsHub';

/**
 * Exposes interface to manage organizations. Mostly used by administrator users from Maestro application.
 */
@injectable('OrganizationService')
export class OrganizationService extends BaseService {
  @inject('OrganizationsApi') protected readonly organizationsApi: OrganizationsApi;
  @inject('UsersAndLocationsApi') protected readonly usersAndLocationsApi: UsersAndLocationsApi;
  @inject('TagsApi') protected readonly tagsApi: TagsApi;
  @inject('AdministratorsApi') protected readonly administratorsApi: AdministratorsApi;
  @inject('AuthService') private readonly authService: AuthService;
  @inject('WsHub') protected readonly wsHub: WsHub;

  constructor() {
    super();
  }

  // #region Base Organization Services

  /**
   * Creates organization from the supplied organization model. Allows to specify parent organization Id.
   * @param {CreateOrganizationModel} organization
   * @param {number} [parentOrganizationId]
   * @returns {Promise<UpdateOrganizationResult>}
   */
  public createOrganization(organization: CreateOrganizationModel, parentOrganizationId?: number): Promise<CreateOrganizationResult> {
    let params = {};

    if (parentOrganizationId) {
      if (parentOrganizationId < 1 || isNaN(parentOrganizationId)) {
        return this.reject(`Parent organization Id is incorrect [${parentOrganizationId}].`);
      }
      params = {organizationId: parentOrganizationId};
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.createOrganization(organization, params));
  }

  /**
   * Updates specified organization with the new entity data.
   * @param {number} organizationId
   * @param {UpdateOrganizationModel} organization
   * @returns {Promise<UpdateOrganizationResult>}
   */
  public updateOrganization(organizationId: number, organization: UpdateOrganizationModel): Promise<UpdateOrganizationResult> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization Id is incorrect [${organizationId}].`);
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.updateOrganization(organization, {organizationId: organizationId}));
  }

  /**
   * Gets organizations objects and properties.
   * @param {number} organizationId
   * @returns {Promise<OrganizationObjectsAndProperties>}
   */
  public listObjectsAndProperties(organizationId: number): Promise<OrganizationObjectsAndProperties> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization Id is incorrect [${organizationId}].`);
    }
    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.listObjectsAndProperties(organizationId));
  }

  /**
   * Saves updated organization's objects and properties array.
   * @param {number} organizationId
   * @param {Array<OrganizationPropertyModel>} objectsAndProperties
   * @returns {Promise<ApiResponseBase>}
   */
  public updateObjectsAndProperties(
    organizationId: number,
    objectsAndProperties: Array<OrganizationPropertyModel>
  ): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization Id is incorrect [${organizationId}].`);
    }
    let props = {organizationObjects: objectsAndProperties};
    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.setOrganizationProperties(organizationId, props));
  }

  /**
   * Deletes object or property.
   * @param {number} organizationId
   * @param {string} objectName
   * @returns {Promise<ApiResponseBase>}
   */
  public deleteObjectOrProperty(organizationId: number, objectName: string): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization Id is incorrect [${organizationId}].`);
    }
    if (!objectName || objectName.length === 0) {
      return this.reject(`Object name can not be empty.`);
    }
    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.deleteLargeObject(organizationId, objectName));
  }

  /** Uploads large object to the specified organization according to the specified params.   *
   * @param {number} organizationId
   * @param {ArrayBuffer} objectFile
   * @param {string} objectName
   * @param {{private?: boolean}} params
   * @param {string} contentType
   * @param {(progressEvent: any) => void} onUploadProgress
   * @returns {Promise<ApiResponseBase>}
   */
  public uploadLargeObject(
    organizationId: number,
    objectFile: ArrayBuffer,
    objectName: string,
    params?: { private?: boolean },
    contentType: string = 'application/octet-stream',
    onUploadProgress?: (progressEvent: any) => void
  ): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization Id is incorrect [${organizationId}].`);
    }
    if (!objectName || objectName.length === 0) {
      return this.reject(`Object name can not be empty.`);
    }
    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.uploadLargeObject(
        objectFile, organizationId, objectName, params, contentType, onUploadProgress));
  }

  /**
   * Deletes specified Large Object.
   * @param {number} organizationId
   * @param {string} objectName
   * @returns {Promise<ApiResponseBase>}
   */
  public deleteLargeObject(organizationId: number, objectName: string): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization Id is incorrect [${organizationId}].`);
    }
    if (!objectName || objectName.length === 0) {
      return this.reject(`Object name can not be empty.`);
    }
    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.deleteLargeObject(organizationId, objectName));
  }

  /**
   * Gets organizations according to the specified parameters.
   * @param {number} organizationId
   * @param {string} domainName
   * @param {string} name
   * @returns {Promise<Organizations>}
   */
  public getOrganizations(domainName?: string, name?: string, organizationId?: number): Promise<Organizations> {
    const params: { organizationId?: number, domainName?: string, name?: string } = {};
    if (!isNaN(organizationId) && organizationId > 0) {
      params.organizationId = organizationId;
    }
    if (domainName && domainName !== '') {
      params.domainName = domainName;
    }
    if (name && name !== '') {
      params.name = name;
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.getOrganizations(params));
  }

  /**
   * Deletes specified organization.
   * @param {number} organizationId
   * @returns {Promise<ApiResponseBase>}
   */
  public deleteOrganization(organizationId: number): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization Id is incorrect [${organizationId}].`);
    }
    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.deleteOrganization({organizationId: organizationId}));
  }

  /**
   * Get total numbers of locations and devices in the organization.
   * @param {number} organizationId Organization ID.
   * @param [params] Request parameters.
   * @param {boolean} [params.locations] Return total number of locations.
   * @param {boolean} [params.groupLocations] Return group locations.
   * @param {boolean} [params.userDevices] Return number of devices.
   * @param {boolean} [params.groupDevices] Return number of group devices.
   * @param {number} [params.groupId] Group ID filter.
   * @param {number} [params.locationId] Location ID filter.
   * @returns {Promise<GetOrganizationTotalsApiResponse>}
   */
  public getOrganizationTotals(organizationId: number, params?: {
    locations?: boolean,
    groupLocations?: boolean,
    userDevices?: boolean,
    groupDevices?: boolean,
    groupId?: number,
    locationId?: number
  }): Promise<GetOrganizationTotalsApiResponse> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization Id is incorrect [${organizationId}].`);
    }
    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.getOrganizationTotals(organizationId, params));
  }

  // #endregion

  // #region Locations in Organization

  /**
   * Gets organization related locations.
   * @param [params] Request parameters.
   * @param {number} [params.organizationId] Organization ID.
   * @param {number} [params.groupId] Group ID.
   * @param {number} [params.locationId] Location ID to retrieve this location.
   * @param {boolean} [params.tree] Retrieve all sub-locations in the selected location tree.
   * @param {string} [params.searchBy] Search by location name and address. Use * for a wildcard.
   * @param {number|number[]} [params.priorityCategory] Filter by Location priority. Multiple supported.
   *   {@see GetOrganizationLocationsLocationApiResponse.priorityCategory}
   * @param {number} [params.locationType] Search field - Location type.
   * @param {string|string[]} [params.searchTag] Search by tag.
   * @param {string|string[]} [params.searchDeviceTag] Search by device tags. Multiple supported.
   * @param {number|number[]} [params.deviceType] Search by devices of these type. Multiple supported.
   * @param {number} [params.servicePlanId] Search by service plan ID.
   * @param {number} [params.stateId] Search field - Location address state ID.
   * @param {number} [params.countryId] Search field - Location address country ID.
   * @param {boolean} [params.getTags] Return location tags.
   * @param {boolean} [params.limit] Limit the response size.
   * @returns {Promise<OrganizationLocations>}
   */
  public getOrganizationLocations(params?: {
    organizationId?: number,
    groupId?: number,
    locationId?: number,
    tree?: boolean,
    searchBy?: string,
    priorityCategory?: number | number[],
    locationType?: LocationType,
    searchTag?: string | string[],
    searchDeviceTag?: string | string[],
    deviceType?: number | number[],
    servicePlanId?: number,
    stateId?: number,
    countryId?: number,
    getTags?: boolean,
    limit?: number,
    sortBy?: string,
    sortOrder?: string,
    sortCollection?: string
  }): Promise<OrganizationLocations> {
    if (!params) {
      params = {};
    }
    params.sortCollection = (params && params.sortCollection) || 'locations';

    return this.authService.ensureAuthenticated()
      .then(() => this.usersAndLocationsApi.getOrganizationLocations(params));
  }

  /**
   * Gets organization narratives by parameters.
   * @param {number} organizationId Organization ID.
   * @param params Request parameters.
   * @param {number} params.rowCount Maximum number of elements per page.
   * @param {number} [params.groupId] Filter by location's group ID.
   * @param {string|string[]} [params.searchTag] Filter by location's tag. Multiple values.
   * @param {number|number[]} [params.locationId] Filter by location ID. Multiple values.
   * @param {number} [params.narrativeId] Filter by narrative ID.
   * @param {NarrativePriority} [params.priority] Filter by priority higher or equal than that.
   * @param {NarrativePriority} [params.toPriority] Filter by priority less or equal than that.
   * @param {NarrativeStatus} [params.status] Filter by status, deleted are not returned by default.
   * @param {NarrativeType | Array<NarrativeType>} Filter by narrative type, multiple values allowed.
   * @param {string} [params.searchBy] Filter by title or description. Use * for a wildcard.
   * @param {string} [params.startDate] Narrative date range start.
   * @param {string} [params.endDate] Narrative date range end.
   * @param {string} [params.pageMarker] Marker to the next page.
   * @returns {Promise<OrganizationNarratives>}
   */
  public getOrganizationNarratives(organizationId: number, params: {
    rowCount: number,
    groupId?: number,
    searchTag?: string | string[],
    locationId?: number | number[],
    narrativeId?: number,
    priority?: NarrativePriority,
    toPriority?: NarrativePriority,
    status?: NarrativeStatus,
    narrativeType?: NarrativeType | Array<NarrativeType>,
    searchBy?: string,
    startDate?: string,
    endDate?: string,
    pageMarker?: string
  }): Promise<OrganizationNarratives> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }
    const count = 100;
    if (!params) {
      params = {
        rowCount: count
      };
    }
    params.rowCount = (params && params.rowCount) || count;
    return this.authService.ensureAuthenticated()
      .then(() => this.usersAndLocationsApi.getOrganizationNarratives(organizationId, params));
  }


  /**
   * Subscribe for Location Narrative updates
   * @param {number} organizationId Organization ID.
   * @param params Request parameters
   * @param {number} params.locationId Location ID.
   * @param {number} [params.groupId] Filter by location's group ID.
   * @param {string|string[]} [params.searchTag] Filter by location's tag. Multiple values.
   * @param {NarrativePriority} [params.priority] Filter by priority higher or equal than that.
   * @param {NarrativePriority} [params.toPriority] Filter by priority less or equal than that.
   * @param {NarrativeStatus} [params.status] Filter by status, deleted are not returned by default.
   * @param {NarrativeType | Array<NarrativeType>} Filter by narrative type, multiple values allowed.
   * @param {string} [params.searchBy] Filter by title or description. Use * for a wildcard.
   * @param {string} [params.startDate] Narrative date range start
   * @param {string} [params.endDate] Narrative date range end
   */
  public subscribeForOrganizationNarratives(organizationId: number, params: {
    locationId?: number | number[],
    groupId?: number,
    searchTag?: string | string[],
    priority?: NarrativePriority,
    toPriority?: NarrativePriority,
    status?: NarrativeStatus,
    narrativeType?: NarrativeType | Array<NarrativeType>,
    searchBy?: string,
    startDate?: string,
    endDate?: string
  }): WsSubscription {
    return this.wsHub.subscribe(
      WsSubscriptionType.ORGANIZATION_NARRATIVES,
      WsSubscriptionOperation.CREATE_OR_UPDATE_OR_DELETE,
      {
        organizationId: organizationId,
        ...params
      });
  }

  // #endregion

  // #region Organization Groups

  /**
   * Gets organization groups according to the filtering options.
   * @param {number} organizationId Organization ID.
   * @param [params] Options to get groups according to.
   * @param {number} [params.groupId] Search field - Group ID
   * @param {string} [params.searchBy] Search by group name. Use * for a wildcard.
   * @param {GroupType} [params.type] Search field - Group type.
   * @param {boolean} [params.locationTotals] Request number of locations in each group.
   * @param {boolean} [params.averageBills] Request average monthly energy bill information (for specific group ID).
   * @param {string} [params.billsStartDate] Monthly bills selection start date. By default it is the current date minus 400 days.
   * @returns {Promise<OrganizationGroups>}
   */
  public getOrganizationGroups(organizationId: number, params?: {
    groupId?: number,
    subOrg?: boolean,
    searchBy?: string,
    type?: GroupType,
    locationTotals?: boolean,
    billsStartDate?: string,
    sortCollection?: string,
    sortBy?: string
  }): Promise<OrganizationGroups> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }
    if (!params) {
      params = {};
    }
    if (!params.sortCollection || params.sortCollection.length === 0) {
      params.sortCollection = 'groups';
    }
    if (!params.sortBy || params.sortBy.length === 0) {
      params.sortBy = 'name';
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.usersAndLocationsApi.getGroups(organizationId, params));
  }

  /**
   * Creates organization group based on the supplied values.
   * @param {number} organizationId
   * @param {CreateGroupModel} group
   * @returns {Promise<CreateGroupResult>}
   */
  public createOrganizationGroup(organizationId: number, group: CreateGroupModel): Promise<CreateGroupResult> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.usersAndLocationsApi.createGroup(organizationId, group));
  }

  /**
   * Updates organization group based on the supplied values.
   * @param {number} organizationId
   * @param groupId
   * @param {UpdateGroupModel} group
   * @returns {Promise<UpdateGroupResult>}
   */
  public updateOrganizationGroup(organizationId: number, groupId: number, group: UpdateGroupModel): Promise<UpdateGroupResult> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }
    if (groupId < 1 || isNaN(groupId)) {
      return this.reject(`Group ID is incorrect [${groupId}].`);
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.usersAndLocationsApi.updateGroup(organizationId, groupId, group));
  }

  /**
   * Deletes the specified organization group.
   * @param {number} organizationId
   * @param {number} groupId
   * @returns {Promise<ApiResponseBase>}
   */
  public deleteOrganizationGroup(organizationId: number, groupId: number): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }
    if (groupId < 1 || isNaN(groupId)) {
      return this.reject(`Group ID is incorrect [${groupId}].`);
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.usersAndLocationsApi.deleteGroup(organizationId, groupId));
  }

  // #endregion

  /**
   * Invites specified users to the specified Organization.
   * @param {number} organizationId Organization Id to invite users to.
   * @param {Array<{ email: string } | { userId: number }>} invitees Array of users to invite.
   * @param {boolean} isReInvite Indicates if it is a subsequent invitation.
   * @returns {Promise<ApiResponseBase>}
   */
  public inviteUsers(organizationId: number, invitees: Array<{ email: string; } | { userId: number; }>, isReInvite: boolean): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }
    if (!invitees || invitees.length === 0) {
      return this.reject('Invitees array should not be null or empty');
    }
    const params: { reinvite?: boolean } = {};
    if (isReInvite) {
      params.reinvite = true;
    }
    return this.authService.ensureAuthenticated()
      .then(() => this.usersAndLocationsApi.postInvitation(organizationId, {invitees: invitees}, params));
  }

  /**
   * Gets Organization's popular tags.
   * @param {number} organizationId If of the organization to get tags for.
   * @param {TagTypes} type Type of the tags to get.
   * @param {number} limit Limit the returned tags count by this number.
   * @returns {Promise<GetPopularTagsResult>}
   */
  public getPopularTags(organizationId: number, type: TagType, limit?: number): Promise<GetPopularTagsResult> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }
    const params: {
      type: TagType,
      sortCollection: string,
      sortBy: string,
      sortOrder: string,
      limit?: number,
    } = {
      type: type,
      sortCollection: 'tags',
      sortBy: 'tag',
      sortOrder: 'asc'
    };

    if (!isNaN(limit) && limit > 0) {
      params.limit = limit;
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.tagsApi.getPopularTags(organizationId, params));
  }

  // #region Administrators and Roles

  /**
   * Get Organization's administrators.
   * @param {number} organizationId Organization ID to get administrators from.
   * @returns {Promise<OrganizationAdmins>}
   */
  public getOrganizationAdmins(organizationId: number): Promise<OrganizationAdmins> {
    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.getOrganizationAdministrators(organizationId));
  }

  /**
   * Adds specified user to the Organization admins list.
   * @param {number} organizationId Organization ID.
   * @param {number} userId Administrator user ID to add to this organization.
   * @param [params] Requesed parameters.
   * @param {string} [params.brand] Notification brand.
   * @returns {Promise<ApiResponseBase>}
   */
  public addUserToOrgAdmins(organizationId: number, userId: number, params?: {
    brand?: string
  }): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }
    if (userId < 1 || isNaN(userId)) {
      return this.reject(`User ID is incorrect [${userId}].`);
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.addOrganizationAdministrator(organizationId, userId, params));
  }

  /**
   * Removes specified user from the Organization admins list.
   * @param {number} organizationId Organization ID.
   * @param {number} userId User ID to remove from this organization.
   * @returns {Promise<ApiResponseBase>}
   */
  public removeUserFromOrgAdmins(organizationId: number, userId: number): Promise<ApiResponseBase> {
    if (organizationId < 1 || isNaN(organizationId)) {
      return this.reject(`Organization ID is incorrect [${organizationId}].`);
    }
    if (userId < 1 || isNaN(userId)) {
      return this.reject(`User ID is incorrect [${userId}].`);
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.organizationsApi.removeOrganizationAdministrator(organizationId, userId));
  }

  /**
   * Returns a list of available roles.
   * @returns {Promise<Roles>}
   */
  public getRoles(): Promise<Roles> {
    return this.authService.ensureAuthenticated()
      .then(() => this.administratorsApi.getRoles());
  }

  /**
   * Grants specified user with the specified role.
   * @param {number} userId User ID to grant this role to.
   * @param {number} roleId The Role ID to grant.
   * @returns {Promise<ApiResponseBase>}
   */
  public grantRoleToUser(userId: number, roleId: number): Promise<ApiResponseBase> {
    if (userId < 1 || isNaN(userId)) {
      return this.reject(`User ID is incorrect [${userId}].`);
    }
    if (roleId < 1 || isNaN(roleId)) {
      return this.reject(`Role ID is incorrect [${roleId}].`);
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.administratorsApi.grantAdministrativeRole(userId, roleId));
  }

  /**
   * Revoke administrative role from specified user.
   * @param {number} userId User ID for which to revoke administrative privileges.
   * @returns {Promise<ApiResponseBase>}
   */
  public revokeAdminRole(userId: number): Promise<ApiResponseBase> {
    if (userId < 1 || isNaN(userId)) {
      return this.reject(`User ID is incorrect [${userId}].`);
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.administratorsApi.revokeAdministrativeRoles(userId));
  }

  // #endregion

}

export interface OrganizationLocations extends GetOrganizationLocationsApiResponse {
}

export interface CreateOrganizationResult extends CreateOrganizationApiResponse {
}

export interface UpdateOrganizationResult extends UpdateOrganizationApiResponse {
}

export interface Roles extends GetRolesApiResponse {
}

export interface Organizations extends GetOrganizationsApiResponse {
}

export interface Organization extends GetOrganizationsApiResponseOrganization {
}

export interface OrganizationObjectsAndProperties extends ListObjectsAndPropertiesApiResponse {
}

export interface GetOrganizationUsersResult extends GetOrganizationUsersApiResponse {
}

export interface OrganizationAdmins extends GetOrganizationAdministratorsApiResponse {
}

export interface OrganizationGroups extends GetGroupsApiResponse {
}

export interface UpdateUser extends UpdateUserApiResponse {
}

export interface CreateGroupResult extends CreateGroupApiResponse {
}

export interface UpdateGroupResult extends UpdateGroupApiResponse {
}

export interface GetPopularTagsResult extends GetPopularTagsApiResponse {
}

export interface OrganizationNarratives extends GetNarrativesApiResponse {
}
