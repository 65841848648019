import {inject, injectable} from '../../../../modules/common/di';
import {AdminApiDal} from '../adminApiDal';
import {GetOrganizationsApiResponse} from './getOrganizationsApiResponse';
import {CreateOrganizationApiResponse, CreateOrganizationModel} from './createOrganizationApiResponse';
import {UpdateOrganizationApiResponse, UpdateOrganizationModel} from './updateOrganizationApiResponse';
import {ListObjectsAndPropertiesApiResponse} from './listObjectsAndPropertiesApiResponse';
import {SetOrganizationPropertiesApiResponse, SetOrganizationPropertiesModel} from './setOrganizationPropertiesApiResponse';
import {GetOrganizationAdministratorsApiResponse} from './getOrganizationAdministratorsApiResponse';
import {GetOrganizationTotalsApiResponse} from './getOrganizationTotalsApiResponse';
import {ApiResponseBase} from '../../../models/apiResponseBase';

/**
 * Organizations API.
 * See {@link https://iotadmins.docs.apiary.io/#reference/organizations}
 *
 * Organization can be created and changed only by a System Administrator.
 * That means: PPC and their partners work directly with customers, who
 * wish to deploy Community Social Networks in their state, county, city, campus, building, or subscriber base.
 *
 * Organizations can be created inside other orgs by users with "organization administrator" permission in the parent organization.
 * Users with both "access all" and "organization administrator" permissions can create top level organizations.
 */
@injectable('OrganizationsApi')
export class OrganizationsApi {

  @inject('AdminApiDal') protected readonly dal: AdminApiDal;

  // #region Manage an Organization

  /**
   * Retrieve organizations information by administrator.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/manage-an-organization/get-organizations}
   *
   * @param [params] Request parameters.
   * @param {number} [params.organizationId] Search field - Specific organization ID to get information about.
   * @param {string} [params.domainName] Search field - Exact domain name.
   * @param {string} [params.name] Search field - First characters of organization name.
   * @returns {Promise<GetOrganizationsApiResponse>}
   */
  getOrganizations(params?: { organizationId?: number, domainName?: string, name?: string }): Promise<GetOrganizationsApiResponse> {
    return this.dal.get('organizations', {params: params});
  }

  /**
   * Creates a new organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/manage-an-organization/create-a-new-organization}
   *
   * @param {CreateOrganizationModel} organization Model of the new organization describing it.
   * @param [params] Request parameters.
   * @param {number} params.organizationId The parent organization ID, where a sub-organization has to be created.
   * @returns {Promise<CreateOrganizationApiResponse>}
   */
  createOrganization(organization: CreateOrganizationModel, params?: { organizationId?: number }): Promise<CreateOrganizationApiResponse> {
    return this.dal.post('organizations', organization, {params: params});
  }

  /**
   * Updates existing organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/manage-an-organization/update-an-existing-organization}
   *
   * @param {UpdateOrganizationModel} model information to update on organization
   * @param params Request parameters.
   * @param {number} params.organizationId The organization ID to update.
   * @returns {Promise<UpdateOrganizationApiResponse>}
   */
  updateOrganization(model: UpdateOrganizationModel, params: { organizationId: number }): Promise<UpdateOrganizationApiResponse> {
    return this.dal.put('organizations', model, {params: params});
  }

  /**
   * Deletes existing organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/manage-an-organization/delete-an-organization}
   *
   * @param params Request parameters.
   * @param {number} params.organizationId The Organization ID to delete.
   * @returns {Promise<ApiResponseBase>}
   */
  deleteOrganization(params: { organizationId: number }): Promise<ApiResponseBase> {
    return this.dal.delete('organizations', {params: params});
  }

  // #endregion

  // #region Organization Large Objects

  /**
   * These API method allows to store large binary or text objects like images, videos and email templates for an organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/organization-large-objects/upload-large-object}
   *
   * This API is only available to Organization Administrators.
   *
   * The Content-Type header must be like `video/*`, `image/*`, `audio/*`, `text/plain` or `application/octet-stream`
   * See {@link https://en.wikipedia.org/wiki/Internet_media_type} for possible exact values.
   * For example: `video/mp4`, `image/jpeg`, `audio/mp3`. The object content gets uploaded as a binary stream.
   *
   * The get organizations API will return a list of uploaded objects.
   *
   * @param {ArrayBuffer} objectFile File with the object content to upload.
   * @param {number} organizationId Id of the organization to upload object to.
   * @param {string} objectName Name of the object being uploaded.
   * @param [params] Request parameters.
   * @param {boolean} [params.private] Set to true, if the object is private.
   * @param [contentType] The Content-Type header. 'application/octet-stream' by default.
   * @param {function} onUploadProgress upload process callback
   * @returns {Promise<ApiResponseBase>}
   */
  uploadLargeObject(
    objectFile: ArrayBuffer,
    organizationId: number,
    objectName: string,
    params?: { private?: boolean },
    contentType: string = 'application/octet-stream',
    onUploadProgress?: (progressEvent: any) => void
  ): Promise<ApiResponseBase> {
    // TODO: In order to actually conveniently use this method we need to introduce the service around it with public access which uses the html5 FileReader
    // component
    return this.dal.put(`organizations/${encodeURIComponent(organizationId.toString())}/objects/${encodeURIComponent(objectName)}`, objectFile, {
      params: params,
      onUploadProgress: onUploadProgress,
      headers: {'Content-Type': contentType}
    });
  }

  /**
   * Return the previously uploaded object content.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/organization-large-objects/get-object-content}
   *
   * Private objects content is available only for organization administrators.
   * Public objects content is available for any user.
   * API_KEY:Optional API key (required for private objects and optional for public).
   *
   * @param {number} organizationId Id of the organization to get object content for.
   * @param {string} objectName Name of the object to get content for.
   * @returns {Promise<ArrayBuffer>}
   */
  getLargeObjectContent(organizationId: number, objectName: string): Promise<ArrayBuffer> {
    // TODO: In order to actually conveniently use this method we need to introduce the service around it with public access The response stream with file
    // content actually can be used as: then(function(response) { response.data.pipe(fs.createWriteStream('ada_lovelace.jpg')) })
    return this.dal.get(
      `organizations/${encodeURIComponent(organizationId.toString())}/objects/${encodeURIComponent(objectName)}`, {responseType: 'stream'});
  }

  /**
   * Delete specified object from the specified organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/organization-large-objects/delete-object}
   *
   * @param {number} organizationId Id of the organization.
   * @param {string} objectName Name of the object to delete.
   * @returns {Promise<ApiResponseBase>}
   */
  deleteLargeObject(organizationId: number, objectName: string): Promise<ApiResponseBase> {
    return this.dal.delete(`organizations/${encodeURIComponent(organizationId.toString())}/objects/${encodeURIComponent(objectName)}`);
  }

  // #endregion

  // #region Organization Objects and Properties

  /**
   * Retrieve all large objects and small properties by the organization. Anyone can call it.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/organization-objects-and-properties/list-objects-and-properties}
   *
   * Private records are turned only for administrators.
   * API_KEY:Optional API key (required for private objects and optional for public)
   *
   * @param {number} organizationId Id of the organization to list objects and properties for.
   * @returns {Promise<ListObjectsAndPropertiesApiResponse>}
   */
  listObjectsAndProperties(organizationId: number): Promise<ListObjectsAndPropertiesApiResponse> {
    return this.dal.get(`organizations/${encodeURIComponent(organizationId.toString())}/objects`);
  }

  /**
   * Update organization property values. Only administrator can call it.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/organization-objects-and-properties/set-properties}
   *
   * @param {number} organizationId Id of the organization to set properties for.
   * @param {SetOrganizationPropertiesModel} properties Properties to set for organization.
   * @returns {Promise<SetOrganizationPropertiesApiResponse>}
   */
  setOrganizationProperties(organizationId: number, properties: SetOrganizationPropertiesModel): Promise<SetOrganizationPropertiesApiResponse> {
    return this.dal.post(`organizations/${encodeURIComponent(organizationId.toString())}/objects`, properties);
  }

  // #endregion

  // #region Manage organization administrators

  /**
   * Get Organization's administrators.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/get-organization-administrators/get-administrators}
   *
   * @param {number} organizationId Organization ID to get administrators from.
   * @returns {Promise<GetOrganizationAdministratorsApiResponse>}
   */
  getOrganizationAdministrators(organizationId: number): Promise<GetOrganizationAdministratorsApiResponse> {
    return this.dal.get(`organizations/${encodeURIComponent(organizationId.toString())}/admins`);
  }

  /**
   * Add user as organization administrator.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/manage-organization-administrators/add-an-administrator}
   *
   * @param {number} organizationId Organization ID.
   * @param {number} userId User ID to add to this organization as Administrator.
   * @param [params] Requested parameters.
   * @param {string} [params.brand] Notification brand.
   * @returns {Promise<ApiResponseBase>}
   */
  addOrganizationAdministrator(organizationId: number, userId: number, params?: {
    brand?: string
  }): Promise<ApiResponseBase> {
    return this.dal.put(`organizations/${encodeURIComponent(organizationId.toString())}/admins/${encodeURIComponent(userId.toString())}`, void 0, {params: params});
  }

  /**
   * Removes specified user from the organization Administrators.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/manage-organization-administrators/remove-administrator}
   *
   * @param {number} organizationId Id of the organization to remove administrator from.
   * @param {number} userId User ID to remove from this organization Administrators.
   * @returns {Promise<ApiResponseBase>}
   */
  removeOrganizationAdministrator(organizationId: number, userId: number): Promise<ApiResponseBase> {
    return this.dal.delete(`organizations/${encodeURIComponent(organizationId.toString())}/admins/${encodeURIComponent(userId.toString())}`);
  }

  // #endregion

  // #region Organization Totals

  /**
   * Get total numbers of locations and devices in the organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/organizations/organization-totals/get-organization-totals}
   *
   * @param {number} organizationId Organization ID.
   * @param [params] Request parameters.
   * @param {boolean} [params.locations] Return total number of locations.
   * @param {boolean} [params.groupLocations] Return group locations.
   * @param {boolean} [params.userDevices] Return number of devices.
   * @param {boolean} [params.groupDevices] Return number of group devices.
   * @param {number} [params.groupId] Group ID filter.
   * @param {number} [params.locationId] Location ID filter.
   * @returns {Promise<GetOrganizationTotalsApiResponse>}
   */
  getOrganizationTotals(organizationId: number, params?: {
    locations?: boolean,
    groupLocations?: boolean,
    userDevices?: boolean,
    groupDevices?: boolean,
    groupId?: number,
    locationId?: number
  }): Promise<GetOrganizationTotalsApiResponse> {
    return this.dal.get(`organizations/${encodeURIComponent(organizationId.toString())}/totals`, {params: params});
  }

  // #endregion

}
