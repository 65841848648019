import {AdminApiDal} from '../adminApiDal';
import {inject, injectable} from '../../../../modules/common/di';
import {GetDevicesApiResponse} from './getDevicesApiResponse';

/**
 * API to manage devices in Organization.
 * See {@link https://iotadmins.docs.apiary.io/#reference/devices/devices}
 */
@injectable('DevicesAdminApi')
export class DevicesAdminApi {

  @inject('AdminApiDal') protected readonly dal: AdminApiDal;

  /**
   * Retrieve device instances linked to locations inside the organization.
   * See {@link https://iotadmins.docs.apiary.io/#reference/devices/devices/get-devices}
   *
   * @param {number} [organizationId] Organization ID.
   * @param [params] Request parameters.
   * @param {number} [params.groupId] Group ID to search within.
   * @param {number} [params.userId] Filter by user ID.
   * @param {number} [params.locationId] Filter by location ID.
   * @param {number} [params.deviceId] Filter by device ID.
   * @param {number|number[]} [params.deviceType] Filter by device types. Multiple values supported.
   * @param {string} [params.searchBy] Search by device ID or description.
   * @param {string} [params.searchTag] Search by tag.
   * @param {string} [params.lessUpdateDate] Request devices, where the last update date is less than this.
   * @param {string} [params.moreUpdateDate] Request devices, where the last update date is more than this.
   * @param {string} [params.paramName] Request devices, which sent this parameter to the cloud.
   * @param {string} [params.paramValue] Requested parameter value.
   * @param {number} [params.limit] Limit the response size by this number.
   * @param {boolean} [params.getTags] Return device tags.
   *
   * @returns {Promise<GetDevicesApiResponse>}
   */
  getDevices(organizationId?: number, params?: {
    groupId?: number,
    userId?: number,
    locationId?: number,
    deviceId?: string,
    deviceType?: number | number[],
    searchBy?: string,
    searchTag?: string,
    lessUpdateDate?: string,
    moreUpdateDate?: string,
    paramName?: string,
    paramValue?: string,
    limit?: number,
    getTags?: boolean
  }): Promise<GetDevicesApiResponse> {
    if (organizationId) {
      // @ts-ignore
      params.organizationId = organizationId;
    }
    return this.dal.get(`devices`, {params: params});
  }

}
