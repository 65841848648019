import {inject, injectable} from '../../modules/common/di';
import {BaseService} from './baseService';
import {ApiResponseBase} from '../models/apiResponseBase';
import {AuthService} from './authService';
import {TagsApi} from '../api/admin/tags/tagsApi';
import {GetPopularTagsApiResponse, TagType} from '../api/admin/tags/popularTagsApiResponse';

@injectable('TagsService')
export class TagsService extends BaseService {

  @inject('AuthService') protected readonly authService: AuthService;
  @inject('TagsApi') protected readonly tagsApi: TagsApi;

  constructor() {
    super();
  }

  /**
   * Allows to get popular tags
   * @param {number} organizationId Organization ID.
   * @param {TagTypes} tagType Tag type filter.
   * @param {number} limit The maximum number of elements to be returned.
   * @returns {Promise<TagsList>}
   */
  public getPopularTags(organizationId: number, tagType: TagType, limit?: number): Promise<TagsList> {
    const params: {
      type: TagType,
      sortCollection: string,
      sortBy: string,
      sortOrder: string,
      limit?: number
    } = {
      type: tagType,
      sortCollection: 'tags',
      sortBy: 'tag',
      sortOrder: 'asc'
    };

    if (limit && !isNaN(limit)) {
      if (limit > 0) {
        params.limit = limit;
      }
    }

    return this.authService.ensureAuthenticated()
      .then(() => this.tagsApi.getPopularTags(organizationId, params));
  }

  /**
   * Allows to apply tags
   * @param {number} organizationId
   * @param {Array<{type: TagTypes; id: string | number; tag: string}>} tags
   * @returns {Promise<ApiResponseBase>}
   */
  public saveTags(organizationId: number, tags: Array<{ type: TagType, id: string | number, tag: string }>)
    : Promise<ApiResponseBase> {
    return this.authService.ensureAuthenticated()
      .then(() => this.tagsApi.applyTags(organizationId, {tags: tags}));
  }

  /**
   * Allows to delete specified tag for the specified entity type.
   * @param {number} organizationId
   * @param {TagTypes} tagType
   * @param {string | number} entityId
   * @param {string} tagValue
   * @param {number} appId
   * @returns {Promise<ApiResponseBase>}
   */
  public deleteTags(organizationId: number, tagType: TagType, entityId: string | number, tagValue: string, appId?: number)
    : Promise<ApiResponseBase> {

    const params: { type: TagType, id: string | number, tag: string, appId?: number } = {
      type: tagType,
      id: entityId,
      tag: tagValue,
      appId: appId
    };

    return this.authService.ensureAuthenticated()
      .then(() => this.tagsApi.deleteTags(organizationId, params));
  }

}

export interface TagsList extends GetPopularTagsApiResponse {
}
